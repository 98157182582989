export default {
  BUTTON_PRIMARY_CLASS: 'cm_btn cm_btn-primary',
  BUTTON_SECONDARY_CLASS: 'cm_btn cm_btn-primary',

  SEARCH_BOX_PLACEHOLDER: 'Search the store...',
  SEARCH_BOX_CLASS: 'wb-formSearch',
  SEARCH_BOX_FORM_CLASS: 'input-group',
  SEARCH_BOX_INPUT_CLASS: 'form-control form-input',
  SEARCH_BOX_BUTTON_CLASS: 'input-group-append',

  PRODUCT_REPEATER_CLASS: 'products-list row',
  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  VEHICLE_WIDGET_HOME_CLASS: 'grid-container',
  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
  VEHICLE_WIDGET_COLLAPSIBLE_LABEL_CLASS: 'cm_vehicle-widget_title',
  SELECT_YOUR_VEHICLE: 'Find Products For <span>Your <b>Vehicle</b></span>',
  SELECT_YOUR_VEHICLE_BLOCK: 'Find Products For <span>Your <b>Vehicle</b></span>',
  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',
};
