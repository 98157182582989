import { tplMap, compMap } from 'Stores/bigcommerce-ayo/config.js?extractConfig';
import processedWidgets, {
  mainConfig as componentlessConfig,
} from 'Models/uiConfig/componentlessWidgetsConfig.ts';

import type { AnyWidget } from 'Templates/widgets.ts';

const resultWidgets: AnyWidget[] = [];

for (const componentless of processedWidgets) {
  const complete = {
    ...componentless,
    component: compMap[componentless.type],
  };

  if ('template' in complete) {
    complete.template = tplMap[complete.template];
  }

  resultWidgets.push(complete);
}

export const mainConfig = {
  ...componentlessConfig,
  Widgets: resultWidgets,
};
export default resultWidgets;
